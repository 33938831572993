@import "./iconfont/iconfont.css";
@import "./reset.scss";
@import "./common.scss";

// 组件懒加载loading容器
.page-loading-container,
#loading {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.4);
	z-index: 11111111;
	display: flex;
	align-items: center;
	justify-content: center;
}
// 404页面
.not-found-page-container {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	img {
		width: 240px;
		height: 226px;
		object-fit: cover;
	}
}

// 无权限页面
.not-authority-page-container {
	width: 100%;
	height: 100%;
	.not-authority-bg {
		background-color: #fff;
		height: 100%;
    position: relative;
		div {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
			img {
				width: 240px;
				height: 226px;
				object-fit: cover;
			}
			span {
				margin-top: 60px;
			}
		}
	}
}

.app-container {
	padding: 20px;
	display: flex;
	display: -webkit-flex;
	min-height: calc(100vh - 98px);
	width: 100%;
}

.page-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
// footer
.footer-container {
	padding: 24px 0;
	box-sizing: border-box;
	font-size: 12px;
	color: #97a1b7;
	.item {
		padding: 0 10px;
		border-right: 1px solid #97a1b7;
		display: flex;
		align-items: center;
		img {
			width: 14px;
			height: 14px;
			object-fit: cover;
			margin-right: 5px;
		}
		&:last-child {
			border-right: 0;
		}
	}
	.footer-text {
		margin-top: 10px;
		a {
			color: #97a1b7;
			text-decoration: none;
		}
	}
}
// 简易版的Header(注册页面)
.header-simple-container {
	min-width: 1080px;
	background-color: #fff;
	box-shadow: 0px 4px 12px 0px rgba(183, 184, 213, 0.15);
	.header-simple {
		width: 1080px;
		height: 60px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: 500;
		color: #4d546a;
		.logo {
			height: 22px;
			margin-right: 10px;
		}
	}
}
// layout布局骨架相关
.layout-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: #f7fbff;
  .layout-main {
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .main-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      overflow-x: auto;
      overflow-x: overlay;
      .breadcrumbs-container {
        padding: 20px 20px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .breadcrumbs {
          display: flex;
          font-size: 14px;
          // line-height: 32px;
          .breadcrumb-item {
            color: #8a90b1;
            .text {
              // cursor: pointer;
            }
            &:last-child {
              color: #1e2b43;
              .text {
                // cursor: initial;
              }
            }
            i {
              margin: 5px;
            }
          }
        }
        .breadCrumb-button {
          position: absolute;
          right: 20px;
          border-radius: 10px;
        }
      }
      .main {
        width: 100%;
        min-width: 1100px;
        padding: 20px 20px 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: hidden;
        position: relative;
      }
    }
  }
}
// 公用logo样式
.common-logo-con {
	flex-shrink: 0;
	width: 100%;
	max-width: 240px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px #f3f7fa solid;
	box-sizing: border-box;
	&.collapsed {
		width: 80px;
		max-width: 80px;
	}
	.img {
		width: 32px;
		height: 32px;
		background: url("../images/logo.png") no-repeat center center;
		background-size: contain;
	}
	.logo-title {
		display: flex;
		align-items: baseline;
		margin-left: 10px;
	}
	.title {
		font-weight: 500;
		color: #4e5767;
		font-size: 16px;
	}
	.sub-title {
		color: #4e5767;
		font-size: 12px;
		margin-left: 8px;
	}
}
// Header(layout)
.header {
	height: 64px;
	background: #fff;
	padding: 0 20px 0 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	box-shadow: 0px 4px 12px 0px rgba(183, 184, 213, 0.15);
	.logo-con {
		@extend .common-logo-con;
		position: relative;
		&::after {
			content: "";
			width: 1px;
			height: 40px;
			background-color: #f3f7fa;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -20px;
		}
	}
	.collapse {
		width: 18px;
		color: #4e5767;
		font-size: 18px;
		margin-left: 20px;
	}
	.header-center {
		flex: 1;
		padding: 0 30px;
		overflow: hidden;
		// 无收起展开图标占位
		&.no-collapse-icon {
			margin-left: 38px;
		}
		// header上tabbar样式覆盖
		.ant-tabs {
			.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
				text-shadow: none;
			}
			.ant-tabs-nav {
				flex: 1;
				margin: 0;
				&::before {
					display: none;
				}
			}
			.ant-tabs-content-holder {
				display: none;
			}
		}
	}
	.header-right {
		display: flex;
		align-items: center;
		.avatar-container {
			width: 34px;
			height: 34px;
			position: relative;
			.img {
				width: 34px;
				height: 34px;
				object-fit: cover;
				border-radius: 34px;
			}
		}
		.user {
			height: 34px;
			line-height: 34px;
			color: #4e5767;
			font-size: 14px;
			margin-left: 16px;
			font-weight: 500;
		}
		.icon-con {
			width: 42px;
			height: 42px;
			background: #f7fbff;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin-left: 20px;
			.iconfont {
				font-size: 14px;
				color: #4e5767;
			}
		}
	}
}
// 左侧菜单
.left-sider-container {
	box-shadow: 0px 4px 12px 0px rgba(183, 184, 213, 0.15);
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #fff;
	.ant-layout-sider-children {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.logo-con {
			@extend .common-logo-con;
		}
	}
}
.title {
	font-weight: 500;
	color: #1e2b43;
	margin-bottom: 20px;
	line-height: 1;
	padding-left: 12px;
	border-left: 3px solid #4084ff;
	font-size: 16px;
}
// ant design menu样式覆盖
.ant-menu.menu-container {
	padding: 0 0 20px 10px;
	border-inline-end: none !important;
	box-sizing: border-box;
	flex: 1;
	overflow: hidden;
	overflow-y: auto;
	overflow-y: overlay;
	&.ant-menu-inline-collapsed {
		padding-left: 0;
	}
	.ant-menu-submenu-arrow {
		color: #8a90b1;
	}
	.ant-menu-item,
	.ant-menu-submenu {
		color: #4e5767;
		border-radius: 0;
		margin-inline: 0;
		margin-block: 0;
		width: 100%;
	}
	.ant-menu-item-active,
	.ant-menu-item-selected,
	.ant-menu-submenu-selected > .ant-menu-submenu-title,
	.ant-menu-submenu-active > .ant-menu-submenu-title,
	.ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow,
	.ant-menu-submenu-active > .ant-menu-submenu-title .ant-menu-submenu-arrow {
		color: #4084ff;
	}
}
.ant-menu-sub.ant-menu-inline {
	background: #fff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #f2f6ff !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu .ant-menu-submenu-title {
	height: 50px !important;
	line-height: 50px !important;
	margin-inline: 0;
	margin-block: 0;
	width: 100%;
	background-color: #fff;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: #4084ff;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
	border-left: 2px solid #4084ff;
	border-right: 0;
	left: 0;
}
.ant-menu-light:not(.ant-menu-horizontal)
	.ant-menu-item:not(.ant-menu-item-selected):hover {
	background-color: #fff;
	color: #4084ff;
}
.ant-menu-light
	.ant-menu-item:hover:not(.ant-menu-item-selected):not(
		.ant-menu-submenu-selected
	),
.ant-menu-light
	.ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
		.ant-menu-submenu-selected
	) {
	color: #4084ff;
}
.add-black {
	margin: 0 16px!important;
	width: 32px;
	height: 32px;
	background: #f2f6ff;
	border-radius: 10px;
	color: #4084FF;
	line-height: 32px;
}

.minus-black {
	width: 32px;
	height: 32px;
	background: #FEF2F0;
	border-radius: 10px;
	color: #FA3C1E;
	line-height: 32px;
}
.ant-form-item-control-input-content{
	font-size: 14px;
}

