@font-face {
  font-family: "iconfont"; /* Project id 4012882 */
  src: url('iconfont.woff2?t=1686032387008') format('woff2'),
       url('iconfont.woff?t=1686032387008') format('woff'),
       url('iconfont.ttf?t=1686032387008') format('truetype'),
       /* url('iconfont.svg?t=1686032387008#iconfont') format('svg'); */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caozuojiluchaxun1:before {
  content: "\e61a";
}

.icon-daochu:before {
  content: "\e61b";
}

.icon-jiaoyiliushuichaxun:before {
  content: "\e61c";
}

.icon-shebeishijianjiluchaxun:before {
  content: "\e61d";
}

.icon-zengjia:before {
  content: "\e618";
}

.icon-shanchu1:before {
  content: "\e619";
}

.icon-chongdianzhuangguanli1:before {
  content: "\e617";
}

.icon-chaxun:before {
  content: "\e614";
}

.icon-zhongzhi:before {
  content: "\e615";
}

.icon-bianji:before {
  content: "\e616";
}

.icon-caozuojiluchaxun:before {
  content: "\e610";
}

.icon-zuhuguanli:before {
  content: "\e611";
}

.icon-shebeijiankongyunwei:before {
  content: "\e612";
}

.icon-xitongyunwei:before {
  content: "\e613";
}

.icon-tuichu:before {
  content: "\e60f";
}

.icon-shanchu:before {
  content: "\e60e";
}

.icon-changjingguanli:before {
  content: "\e603";
}

.icon-dingdanguanli:before {
  content: "\e604";
}

.icon-duankouguanli:before {
  content: "\e605";
}

.icon-jijiaguize:before {
  content: "\e606";
}

.icon-shebeiweihu:before {
  content: "\e607";
}

.icon-shebeiguanli:before {
  content: "\e608";
}

.icon-xitongpeizhi:before {
  content: "\e609";
}

.icon-baobiaoguanli:before {
  content: "\e60a";
}

.icon-jiluchaxun:before {
  content: "\e60b";
}

.icon-renyuanxinxi:before {
  content: "\e60c";
}

.icon-peizhiguanli:before {
  content: "\e60d";
}

