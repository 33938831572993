// 字号
$fontSizeArr: 8, 10, 12, 14;
@each $item in $fontSizeArr {
  .fs-#{$item} {
    @if $item >=12 {
      font-size: #{$item}px;
    } @else {
      // 小于12号利用scale实现，注意className要加在文字上面，否则box会整体缩小
      font-size: #{$item * 2}px !important;
      transform: scale(0.5);
    }
  }
}
// 外边距
$marginArr: 5, 10, 15, 20, 24, 30, 40;
@each $item in $marginArr {
  .mt-#{$item} {
    margin-top: #{$item}px;
  }
  .mb-#{$item} {
    margin-bottom: #{$item}px;
  }
  .mr-#{$item} {
    margin-right: #{$item}px;
  }
  .ml-#{$item} {
    margin-left: #{$item}px;
  }
}

.h-36 {
  height: 36px;
  border-radius: 10px;
  .ant-select-selector {
    height: 36px !important;
    border-radius: 10px;
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 34px !important;
    }
  }
}

.wd-68 {
  width: 68px;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-c-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table-container {
  background: #fff;
  padding: 13px 0 15px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  .search-container {
    border-bottom: 1px solid #f3f7fa;
    margin-bottom: 12px;
    padding: 0px 10px 13px 10px;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      justify-content: center;
    }
  }
  .standard-table{
    margin-left: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
    .ant-table-tbody>tr>td {
      height: 52px;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #4e5767;
      padding: 0!important;
      line-height: 52px;
    }
  }
}
.option-button-blue {
  background-color: #4084ff;
  height: 36px;
  line-height: 36px;
  padding: 0 19px;
  border-radius: 10px;
}
.relative{
  position: relative;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.icon {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  font-size: 13px;
  &.icon-edit {
    background: #f2f6ff;
    color: #4084ff;
  }
  &.icon-del {
    background: #fef2f0;
    color: #fa3c1e;
  }
}
.operation-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.table-container {
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  .even-row {
    td {
      background-color: #fafbfd;
    }
  }
  // antd Table组件样式覆盖
  .ant-table-wrapper {
    .ant-table-thead {
      & > tr {
        & > th,
        & > td {
          text-align: center;
          background: #f3f7fa;
          border-bottom: none;
          color: #1e2b43;
          font-size: 16px;
          font-weight: 400;
          &:first-child {
            border-start-start-radius: unset !important;
            border-radius: 10px 0 0 10px !important;
          }
          &:nth-last-child(2) {
            border-start-end-radius: unset !important;
            border-radius: 0 10px 10px 0 !important;
          }
          &.ant-table-cell-scrollbar {
            background: #fff;
            &:not([rowspan]) {
              box-shadow: none;
            }
          }
          &::before {
            display: none;
          }
        }
      }
    }
    .ant-table-tbody {
      > tr {
        & > td {
          text-align: center;
          color: #4e5767;
          border: none;
          &:first-child {
            border-start-start-radius: unset !important;
            border-radius: 10px 0 0 10px !important;
          }
          &:last-child {
            border-start-end-radius: unset !important;
            border-radius: 0 10px 10px 0 !important;
          }
        }
      }
    }
    // 表格空数据样式
    .ant-table-empty {
      .ant-table-container {
        .ant-table-body {
          & > table {
            height: 100%;
          }
        }
      }
    }
  }
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container,
  .ant-table-body {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: hidden;
  }
}
.table-container {
  height: 100%;
  .ant-table-wrapper {
    height: 94%;
  }
  .ant-table-body {
    overflow-x: auto !important;
    overflow-x: overlay !important;
    max-height: 600px !important;
  }
}

// 分页样式
.pagination-container {
  padding-top: 20px;
  .ant-pagination {
    text-align: right;
    li.ant-pagination-total-text {
      float: left;
      line-height: 40px;
    }
    li.ant-pagination-prev,
    li.ant-pagination-item,
    li.ant-pagination-jump-prev,
    li.ant-pagination-jump-next,
    li.ant-pagination-next {
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 10px;
    }
    li.ant-pagination-item {
      a {
        line-height: 40px;
      }
    }
    li.ant-pagination-item-active {
      background: #4084ff;
      a {
        color: #fff;
      }
    }
    li.ant-pagination-jump-prev,
    li.ant-pagination-jump-next {
      .ant-pagination-item-ellipsis,
      .ant-pagination-item-link-icon {
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    li.ant-pagination-options {
      display: inline;
      .ant-select {
        float: left;
        width: 88px;
        height: 40px;
        background: #fff;
        border-radius: 10px;
        .ant-select-selector {
          border: 0;
          padding: 0 3px;
          height: 40px;
          span.ant-select-selection-item {
            display: flex;
            line-height: 40px;
            padding-right: 0;
            right: -3px;
          }
        }
        span.ant-select-arrow {
          width: 10px;
          height: 6px;
          top: calc(50% + 3px);
          right: 8px;
        }
        .ant-select-item {
          div.ant-select-item-option-content {
            display: flex;
            padding-right: 0;
          }
        }
      }
      .ant-pagination-options-quick-jumper {
        vertical-align: unset;
        height: 40px;
        input {
          width: 40px;
          height: 40px;
          background: #ffffff;
          border-radius: 10px;
          border: 0;
          padding: 14px 13px 14px 12px;
          text-align: center;
        }
      }
    }
  }
}
.search-form-icon {
  flex: 1;
  text-align: right;
}

// tab
.tab-title {
	// width: 100%;
	height: 40px;
	background: #f6fafd;
	border-radius: 4px;
	line-height: 40px;
  &.list-tab{
    margin: 0 20px 16px
  }
  &.config-tab{
    margin-top: 16px;
  }
	> button {
		width: 118px;
		height: 32px;
		border: none;
		font-size: 14px;
		font-family: PingFang SC, PingFang SC-Regular;
		font-weight: 400;
		color: #72a4fd;
		margin-left: 10px;
		background: none;
	}
	.active {
		box-shadow: 0px 4px 12px 0px rgba(64, 132, 255, 0.1);
		border-radius: 10px;
		background: #ffffff;
	}
}

.ant-select-selection-placeholder{
  display: block;
  width: 80% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m-r-l-5{
  margin: 0 5px;
}

.c-4084ff{
  color: #4084ff
}

// 表单页的提示框-1
.form-tip-text-1{
  display: inline-block;
  width: 170px;
}